import React from "react";

const Slide5 = () => {
  return (
    <iframe
      src="/html/templateSlide5/tempSlide.html"
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Slide 5"
    />
  );
};

export default Slide5;
