import React from "react";

const SlideAsiaTour = () => {
  return (
    <iframe
      src="/html/templateSlideAsiaTour/tempSlide.html"
      style={{ width: "100%", height: "700px", border: "none" }}
      title="Slide NorthTour"
    />
  );
};

export default SlideAsiaTour;
