import React from "react";

const SlideEuropeTour = () => {
  return (
    <iframe
      src="/html/templateSlideEuropeTour/tempSlide.html"
      style={{ width: "100%", height: "700px", border: "none" }}
      title="Slide NorthTour"
    />
  );
};

export default SlideEuropeTour;
