import React from "react";
import BlogSection from "./BlogSection";
//import "../../../styles/bLog.css";

const Blog = () => {
  return (
    <div className="blog-container">
      <BlogSection />
    </div>
  );
};

export default Blog;
