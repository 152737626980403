import React from "react";
import Slide5 from "../../Slideshow/SLide5";

const Pages = () => {
  return (
    <div>
      <Slide5 />
    </div>
  );
};

export default Pages;
