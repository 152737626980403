import React, { useState } from "react";
import ListCard from "./ListCard";
import "../../../../styles/TourSection.module.css";

export default function TourSection() {
  return (
    <div>
      <ListCard />
    </div>
  );
}
