import React from "react";

const SlideAmericaTour = () => {
  return (
    <iframe
      src="/html/templateSlideAmericaTour/tempSlide.html"
      style={{ width: "100%", height: "700px", border: "none" }}
      title="Slide NorthTour"
    />
  );
};

export default SlideAmericaTour;
