import React from "react";

const Slide2 = () => {
  return (
    <iframe
      src="/html/templateSlide2/tempSlide.html"
      style={{ width: "100%", height: "700px", border: "none" }}
      title="Slide 2"
    />
  );
};

export default Slide2;
